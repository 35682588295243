import {
  NoChargersAvailable,
  Recharging,
  StationOffline,
} from '~assets/icons/station-status';
import { MessageInfo } from '~types/common.type';
import { StationStatus } from '~types/station.type';

export const stationStatusMessages: Record<StationStatus, MessageInfo> = {
  [StationStatus.OFFLINE]: {
    localeTextId: 'app.badStatusStationPage.text.stationStatus.online',
    title: 'Station is Currently Offline',
    img: StationOffline,
  },
  [StationStatus.RECHARGING]: {
    localeTextId: 'app.badStatusStationPage.text.stationStatus.recharging',
    title: 'Chargers are Recharging',
    img: Recharging,
  },
  [StationStatus.NO_CHARGERS_AVAILABLE]: {
    localeTextId:
      'app.badStatusStationPage.text.stationStatus.noChargersAvailable',
    title: 'No Chargers Available',
    img: NoChargersAvailable,
  },
};

import { useCallback, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import MoreIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  IconButton,
  Menu,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { Theme } from '@mui/system';

import { LanguageSelect } from '~components/molecules';
import { LocalizationTypography } from '~components/organisms';
import { handleOpenApp } from '~utils';

/**
 * @deprecated Use `NavbarViewV1` - with FF `CONFIGURABLE_WEB_APP_BANNER` instead.
 * @deprecationReason To support custom navbar and release web app banner feature
 */
export const NavbarView: React.FC = () => {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setMobileMoreAnchorEl(event.currentTarget);
    },
    [setMobileMoreAnchorEl],
  );

  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md'),
  );

  // update screen size is changed
  useUpdateEffect(() => {
    if (isLargeScreen) setMobileMoreAnchorEl(null);
  }, [isLargeScreen]);

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      sx={{
        '.MuiPaper-root': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <Box width={'250px'} display={'flex'}>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          padding={2}
          alignItems={'flex-end'}
          display={'flex'}
          flexDirection={'column'}
          sx={{
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0px 3px 14px rgba(0, 0, 0, 0.08)',
          }}
          gap={2}
        >
          {/* LANGUAGE SELECTION */}
          <LanguageSelect />

          {/* BUTTON USE APP */}
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: 5 }}
            onClick={handleOpenApp}
          >
            <LocalizationTypography
              variant="body2"
              fontWeight={500}
              textId="app.mainLayout.buttonUseApp"
              defaultText="Use App"
            />
          </Button>
        </Box>
      </Box>
    </Menu>
  );

  return (
    <>
      <img src="/images/logo-black.svg" alt="logo" width={'130'} />
      <Stack direction={'row'} spacing={2} display={{ xs: 'none', md: 'flex' }}>
        {/* LANGUAGE SELECTION */}
        <LanguageSelect />

        {/* BUTTON USE APP */}
        <Button
          variant="contained"
          color="primary"
          sx={{ borderRadius: 5 }}
          onClick={handleOpenApp}
        >
          <LocalizationTypography
            variant="body2"
            fontWeight={500}
            textId="app.mainLayout.buttonUseApp"
            defaultText="Use App"
          />
        </Button>
      </Stack>

      {/* Responsive button for mobile */}
      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size="large"
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
        >
          <MoreIcon />
        </IconButton>
      </Box>
      {renderMobileMenu}
    </>
  );
};

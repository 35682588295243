import React, { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { createContext, useContext } from 'use-context-selector';

import { TRACKING_BUTTON, TRACKING_EVENTS, TRACKING_PAGES } from '~common';

type EventTrackingContextType = {
  identifyUser: (userId: string) => void;
  recordPageVisited: (
    pageName: TRACKING_PAGES,
    properties?: Record<string, unknown>,
  ) => void;
  recordEvent: (
    eventName: TRACKING_EVENTS,
    properties?: Record<string, unknown>,
  ) => void;
  recordBtnClicked: (
    buttonName: TRACKING_BUTTON,
    properties?: Record<string, unknown>,
  ) => void;
  isStationOfflineRecorded: boolean;
  setIsStationOfflineRecorded: React.Dispatch<React.SetStateAction<boolean>>;
  isWebAppBannerDisplayedRecorded: boolean;
  setIsWebAppBannerDisplayedRecorded: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

const EventTrackingContext = createContext<EventTrackingContextType>({
  identifyUser: () => null,
  recordPageVisited: () => null,
  recordEvent: () => null,
  recordBtnClicked: () => null,
  isStationOfflineRecorded: false,
  setIsStationOfflineRecorded: () => null,
  isWebAppBannerDisplayedRecorded: false,
  setIsWebAppBannerDisplayedRecorded: () => null,
});

type Props = {
  children: React.ReactNode;
};

export const EventTrackingProvider: React.FC<Props> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const stationSerialNumber = searchParams.get('box');

  const [defaultProps] = useState({
    stationSerialNumber: stationSerialNumber,
  });

  /**
   * This state ensures that the station offline event is recorded only once per user per session,
   * even when the user navigates to other pages.
   */
  const [isStationOfflineRecorded, setIsStationOfflineRecorded] =
    useState(false);

  /**
   * This state ensures that the web app banner is displayed event is recorded each 5 minutes per user per session,
   * even when the user navigates to other pages.
   */
  const [isWebAppBannerDisplayedRecorded, setIsWebAppBannerDisplayedRecorded] =
    useState(false);

  const [analytics, _] = useState(
    AnalyticsBrowser.load(
      {
        writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY ?? '',
      },
      {
        integrations: {
          'Segment.io': {
            deliveryStrategy: {
              strategy: 'batching',
              config: {
                size: 10,
                timeout: 5000,
              },
            },
          },
        },
      },
    ),
  );

  const identifyUser = useCallback(
    (userId: string) => {
      analytics
        .identify(userId, {
          $onesignal_user_id: userId,
        })
        .then(() => true)
        // eslint-disable-next-line no-console
        .catch((e) => console.error('Segment Error: ', e));
    },
    [analytics],
  );

  const recordPageVisited = useCallback(
    (pageName: TRACKING_PAGES, properties: Record<string, unknown> = {}) => {
      analytics
        .page(undefined, pageName, {
          ...properties,
          ...defaultProps,
        })
        .then(() => true)
        // eslint-disable-next-line no-console
        .catch((e) => console.error('Segment Error: ', e));
    },
    [analytics, defaultProps],
  );

  const recordEvent = useCallback(
    (eventName: TRACKING_EVENTS, properties: Record<string, unknown> = {}) => {
      analytics
        .track(eventName.toString(), {
          ...properties,
          ...defaultProps,
        })
        .then(() => true)
        // eslint-disable-next-line no-console
        .catch((e) => console.error('Segment Error: ', e));
    },
    [analytics, defaultProps],
  );

  const recordBtnClicked = useCallback(
    (buttonName: TRACKING_BUTTON, properties: Record<string, unknown> = {}) => {
      analytics
        .track(TRACKING_EVENTS.BUTTON_CLICKED, {
          buttonName: buttonName,
          ...properties,
          ...defaultProps,
        })
        .then(() => true)
        // eslint-disable-next-line no-console
        .catch((e) => console.error('Segment Error: ', e));
    },
    [analytics, defaultProps],
  );

  const contextValue = useMemo(
    () => ({
      identifyUser,
      recordPageVisited,
      recordEvent,
      recordBtnClicked,
      isStationOfflineRecorded,
      setIsStationOfflineRecorded,
      isWebAppBannerDisplayedRecorded,
      setIsWebAppBannerDisplayedRecorded,
    }),
    [
      identifyUser,
      recordPageVisited,
      recordEvent,
      recordBtnClicked,
      isStationOfflineRecorded,
      setIsStationOfflineRecorded,
      isWebAppBannerDisplayedRecorded,
    ],
  );

  return (
    <EventTrackingContext.Provider value={contextValue}>
      {children}
    </EventTrackingContext.Provider>
  );
};

export const useEventTrackingContext = () => useContext(EventTrackingContext);

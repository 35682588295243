import { BAD_STATION_STATUS_PAGE_PATH } from '~configs';

export const DEFAULT_PRE_AUTH_AMOUNT = 1000;

export const DEFAULT_LANGUAGE_CODE = 'en-US';

export const REDEEM_REWARD_INVALID_CODE = {
  // Referral
  INVALID_REFERRAL_CODE: 1018,
  REWARD_NOT_YET_AVAILABLE: 1020,
  REWARD_NOT_FOUND: 1017,
  // Promotion
  PROMOTION_NOT_YET_AVAILABLE: 1032,
  INVALID_PROMOTION_CODE: 1035,
};

export const ALREADY_REDEEM_REWARD_CODE = {
  // Referral
  REWARD_ALREADY_REDEEMED: 1019,
  // Promotion
  PROMOTION_ALREADY_REDEEMED: 1031,
  PROMOTION_USER_USAGE_REACH_LIMIT: 1034,
};

export const INPUT_BORDER_STYLES = {
  DEFAULT: '1px solid #DBDBDB',
  FOCUSED: '1px solid #3E8ABF',
  ERROR: '2px solid #B83031',
};

export const EJECT_BATTERY_BAD_STATUS_CODE = {
  STATION_OFFLINE: 1511,
};

// redirect to bad status page based on the code
export const REDIRECT_BAD_STATUS_PAGE = {
  [EJECT_BATTERY_BAD_STATUS_CODE.STATION_OFFLINE]: `${BAD_STATION_STATUS_PAGE_PATH}/offline`,
};

/* eslint-disable max-len */

export const Recharging = () => {
  return (
    <svg
      width="121"
      height="120"
      viewBox="0 0 121 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2130_16726"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="121"
        height="120"
      >
        <rect x="0.5" width="120" height="120" fill="white" />
      </mask>
      <g mask="url(#mask0_2130_16726)">
        <path
          d="M30.5 16.043C30.5 9.39184 35.7672 4 42.2647 4H78.7353C85.2328 4 90.5 9.39184 90.5 16.043V103.957C90.5 110.608 85.2328 116 78.7353 116H42.2647C35.7672 116 30.5 110.608 30.5 103.957V16.043Z"
          fill="#ECF8FF"
        />
        <path
          d="M44.3242 73.2466C37.5595 73.2463 35.2066 74.4509 32.2654 76.5584V110.279L36.6771 114.193H84.9125L89.3242 108.171V72.6445C87.2654 73.5477 69.326 75.6552 65.7948 75.6552C55.5007 75.6552 52.8739 73.2471 44.3242 73.2466Z"
          fill="#47D6FA"
        />
        <path
          d="M47.5595 83.8054C40.736 84.2871 35.0105 83.0025 32.2654 80.1925V107.5L39 114.495L83 113.5L89.3242 106L89.3242 73.8699C88.5399 73.1674 85.1477 71.2209 77.8536 71.4618C68.736 71.7629 56.0889 83.2032 47.5595 83.8054Z"
          fill="#74E0FB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M78.7353 7.6129H42.2647C37.7165 7.6129 34.0294 11.3872 34.0294 16.043V103.957C34.0294 108.613 37.7165 112.387 42.2647 112.387H78.7353C83.2835 112.387 86.9706 108.613 86.9706 103.957V16.043C86.9706 11.3872 83.2835 7.6129 78.7353 7.6129ZM42.2647 4C35.7672 4 30.5 9.39184 30.5 16.043V103.957C30.5 110.608 35.7672 116 42.2647 116H78.7353C85.2328 116 90.5 110.608 90.5 103.957V16.043C90.5 9.39184 85.2328 4 78.7353 4H42.2647Z"
          fill="#009EFA"
        />
        <mask
          id="mask1_2130_16726"
          maskUnits="userSpaceOnUse"
          x="36"
          y="36"
          width="49"
          height="48"
        >
          <rect x="36.5" y="36" width="48" height="48" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask1_2130_16726)">
          <path
            d="M58.1786 40.8L43.847 59.9412H54.4412L43.7 79.2L67.7431 55.2148H58.0904L68.6845 40.8H58.1786Z"
            fill="#009EFA"
          />
          <path
            d="M70.029 48.4875L75.474 40.8H69.9143L64.2629 48.4749L70.029 48.4875Z"
            fill="#009EFA"
          />
          <path
            d="M74.8612 48.4875L80.1085 40.8H76.6713L71.2185 48.4749L74.8612 48.4875Z"
            fill="#009EFA"
          />
        </g>
      </g>
    </svg>
  );
};

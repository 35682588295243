import { memo } from 'react';
import { Button, SxProps } from '@mui/material';

export interface CustomComponentProps {
  onClick?: () => void;
  as?: React.ElementType;
  children?: React.ReactNode;
  onlyChildren?: boolean;
  sxProps?: SxProps;
}

export const CustomComponent = memo(
  ({
    onClick,
    as: Component,
    children,
    onlyChildren = false,
    sxProps = {},
  }: CustomComponentProps) => {
    if (onlyChildren) return <>{children}</>;

    return Component ? (
      <Component sx={sxProps} onClick={onClick}>
        {children}
      </Component>
    ) : (
      <Button
        variant="contained"
        color="primary"
        sx={{ borderRadius: 5, minWidth: 125, ...sxProps }}
        onClick={onClick}
      >
        {children ? children : 'Use App'}
      </Button>
    );
  },
);

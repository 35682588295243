import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SxProps, Typography } from '@mui/material';

import { TRACKING_BUTTON, TRACKING_PAGES } from '~common';
import { Navbar } from '~components/atoms';
import { CustomComponentProps } from '~components/atoms/custom';
import { BAD_STATION_STATUS_PAGE_PATH } from '~configs';
import {
  useAppBannerSettingContext,
  useEventTrackingContext,
} from '~providers';
import { BannerCtaType } from '~types/app-banner.type';
import {
  getDeepLinkForApp,
  handleOpenDeepLink,
  handleOpenLinkInNewTab,
} from '~utils';

export const NavbarWrapper: FC = () => {
  const location = useLocation();
  const onBadStatusStationPage = location.pathname.includes(
    BAD_STATION_STATUS_PAGE_PATH,
  );

  const {
    configurableWebAppBannerFeature,
    appBannerSetting,
    isAppBannerSettingReady,
  } = useAppBannerSettingContext();

  const navBarRef = useRef<HTMLDivElement>(null);

  const isCtaDisabled = useMemo(
    () =>
      isAppBannerSettingReady &&
      (!appBannerSetting?.ctaType ||
        appBannerSetting.ctaType === BannerCtaType.NONE),
    [appBannerSetting?.ctaType, isAppBannerSettingReady],
  );

  const {
    recordPageVisited,
    recordBtnClicked,
    isWebAppBannerDisplayedRecorded,
    setIsWebAppBannerDisplayedRecorded,
  } = useEventTrackingContext();

  const [visibilityState, setVisibilityState] = useState(
    document.visibilityState,
  );

  const recordBannerDisplayed = useCallback(() => {
    if (
      isAppBannerSettingReady &&
      visibilityState === 'visible' &&
      !isWebAppBannerDisplayedRecorded
    ) {
      recordPageVisited(TRACKING_PAGES.WEB_BANNER_DISPLAYED, {
        bannerTitle: appBannerSetting?.content || '',
      });
      setIsWebAppBannerDisplayedRecorded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAppBannerSettingReady,
    isWebAppBannerDisplayedRecorded,
    recordPageVisited,
    setIsWebAppBannerDisplayedRecorded,
    visibilityState,
  ]);

  // Update the visibility state based on the document's visibility
  useEffect(() => {
    const handleVisibilityChange = () => {
      setVisibilityState(document.visibilityState);
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  /**
   * Set an interval to record every 5 minutes (300000 ms)
   * Reset the recorded flag and re-attempt tracking every 5 minutes
   */
  useEffect(() => {
    recordBannerDisplayed();
    const intervalId = setInterval(() => {
      setIsWebAppBannerDisplayedRecorded(false);
    }, 300000);

    // Clear interval
    return () => clearInterval(intervalId);
  }, [
    recordBannerDisplayed,
    setIsWebAppBannerDisplayedRecorded,
    visibilityState,
  ]);

  // to display banner content
  const navBarLeftComponent = useMemo(
    () =>
      isAppBannerSettingReady
        ? {
            onlyChildren: true,
            children: (
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  fontSize: '14px',
                  lineHeight: '20px',
                  color: 'white',
                  textAlign: isCtaDisabled ? 'center' : 'left',
                }}
              >
                {appBannerSetting?.content}
              </Typography>
            ),
          }
        : undefined,
    [appBannerSetting?.content, isAppBannerSettingReady, isCtaDisabled],
  );

  // to display cta
  const navBarRightComponent = useMemo(() => {
    if (onBadStatusStationPage) return 'empty';

    if (!isAppBannerSettingReady) return undefined;

    if (isCtaDisabled) return 'empty';

    const component: CustomComponentProps = {
      sxProps: {
        ml: '16px',
        bgcolor: 'white',
        color: '#008FE1',
        ':hover': {
          bgcolor: '#C9D4DB',
        },
      },
      children: (
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
          }}
        >
          {appBannerSetting?.ctaText}
        </Typography>
      ),
    };

    const handleTrackBannerCtaClick = (link?: string) => {
      recordBtnClicked(TRACKING_BUTTON.BANNER_CTA, {
        bannerTitle: appBannerSetting?.content || '',
        bannerLink: link || '',
      });
    };

    if (appBannerSetting?.ctaType === BannerCtaType.INTERNAL)
      return {
        onClick: () => {
          const deepLink = getDeepLinkForApp();
          handleTrackBannerCtaClick(deepLink);
          handleOpenDeepLink(deepLink);
        },
        ...component,
      };

    if (appBannerSetting?.ctaType === BannerCtaType.EXTERNAL)
      return {
        onClick: () => {
          if (appBannerSetting.ctaLink) {
            handleTrackBannerCtaClick(appBannerSetting.ctaLink);
            handleOpenLinkInNewTab(appBannerSetting.ctaLink);
          }
        },
        ...component,
      };

    return undefined;
  }, [
    appBannerSetting?.content,
    appBannerSetting?.ctaLink,
    appBannerSetting?.ctaText,
    appBannerSetting?.ctaType,
    isAppBannerSettingReady,
    isCtaDisabled,
    onBadStatusStationPage,
    recordBtnClicked,
  ]);

  const navbarContainerProps: SxProps = useMemo(
    () =>
      onBadStatusStationPage
        ? {
            justifyContent: 'center',
          }
        : configurableWebAppBannerFeature && isAppBannerSettingReady
        ? {
            backgroundImage: 'url(/images/banner-background.png)',
            backgroundSize: '125% auto',
            backgroundRepeat: 'no-repeat',
          }
        : {},
    [
      configurableWebAppBannerFeature,
      isAppBannerSettingReady,
      onBadStatusStationPage,
    ],
  );

  return (
    <Navbar
      ref={navBarRef}
      leftComponent={navBarLeftComponent}
      rightComponent={navBarRightComponent}
      sxProps={navbarContainerProps}
    />
  );
};

import { Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import { Skeleton } from '@mui/material';

import { initI18n } from './i18n';

import {
  BadStatusStationPage,
  ErrorPage,
  HomePage,
  RentalPage,
  SuccessPage,
  WelcomeBackPage,
} from '~components/pages';
import { MainLayout } from '~components/templates';
import {
  BAD_STATION_STATUS_PAGE_PATH,
  HOMEPAGE_PATH,
  RENTAL_PAGE_PATH,
  SUCCESS_PAGE_PATH,
  WELCOME_BACK_PAGE_PATH,
} from '~configs';
import {
  AppBannerSettingProvider,
  LanguageProvider,
  StationStatusProvider,
} from '~providers';
import { featureFlagsService } from '~services';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path={HOMEPAGE_PATH}
        loader={async () => {
          try {
            // Fetch flags from service rental
            await featureFlagsService.handleFetchFeatureFlags();

            // i18n initialization
            initI18n();
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Failed to load resources with reason:', err);
          }

          // loader requires a return
          return null;
        }}
        element={
          // Suspense is used to wait for i18n initialization
          <Suspense fallback={<Skeleton />}>
            <LanguageProvider>
              <StationStatusProvider>
                <AppBannerSettingProvider>
                  <MainLayout />
                </AppBannerSettingProvider>
              </StationStatusProvider>
            </LanguageProvider>
          </Suspense>
        }
      >
        <Route index element={<HomePage />} />
        <Route path={RENTAL_PAGE_PATH} element={<RentalPage />} />
        <Route path={SUCCESS_PAGE_PATH} element={<SuccessPage />} />
        <Route path={WELCOME_BACK_PAGE_PATH} element={<WelcomeBackPage />} />
        <Route path={BAD_STATION_STATUS_PAGE_PATH}>
          <Route path={`:status`} element={<BadStatusStationPage />} />
        </Route>
      </Route>
      {/* OTHER ROUTE */}
      <Route path="*" element={<ErrorPage />} />
    </>,
  ),
);

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useNavigateInApp } from '.';

import { QUERY_KEY, RENTAL_FAILED_REASON, RENTAL_STATE } from '~common';
import { SUCCESS_PAGE_PATH } from '~configs';
import { useLanguageContext } from '~providers';
import { getRequest } from '~services';

export const useQueryGetRentalState = (rentalId?: string) => {
  const navigate = useNavigateInApp();
  const [intervalDuration, setIntervalDuration] = useState(1000);

  const { lt } = useLanguageContext();

  const queryClient = useQueryClient();
  const queryInfo = useQuery(
    [QUERY_KEY.GET_RENTAL_STATE, rentalId],
    () => {
      return getRequest({
        path: `api/rental/rental-state?id=${rentalId}`,
      }).then((res) => res);
    },
    {
      onSuccess: (data) => {
        if (data.rentalState !== RENTAL_STATE.WAITING) {
          switch (data.rentalState) {
            case RENTAL_STATE.FAILED:
              toast.error(
                lt(
                  `text.hooks.useQueryGetRentalState.RENTAL_FAILED_REASON.${data.failedReason}`,
                  RENTAL_FAILED_REASON[data.failedReason],
                ) ||
                  lt(
                    'text.hooks.useQueryGetRentalState.error',
                    'An error occurred when requesting the battery.',
                  ),
              );
              break;
            case RENTAL_STATE.ACTIVE:
              toast.success(
                lt(
                  'text.hooks.useQueryGetRentalState.success',
                  'Request battery success!',
                ),
              );
              navigate(SUCCESS_PAGE_PATH, { replace: true });
              break;
          }
        }
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          toast.error(
            error?.response?.data?.message ??
              lt(
                'text.hooks.useQueryGetRentalState.error',
                'An error occurred when requesting the battery.',
              ),
          );
        } else {
          toast.error(
            lt(
              'text.hooks.useQueryGetRentalState.error',
              'An error occurred when requesting the battery.',
            ),
          );
        }
      },

      enabled: !!rentalId,
    },
  );

  useEffect(() => {
    // Set an interval to periodically invalidate the query with increasing duration.
    const interval = setInterval(() => {
      // Check the condition and invalidate the query if needed.
      if (queryInfo.data?.rentalState === RENTAL_STATE.WAITING) {
        queryClient.invalidateQueries([QUERY_KEY.GET_RENTAL_STATE, rentalId]);
        setIntervalDuration(intervalDuration * 2); // Double the interval duration
      }
    }, intervalDuration);

    // Clear the interval when the component unmounts to avoid memory leaks.
    return () => clearInterval(interval);
  }, [queryClient, rentalId, queryInfo, intervalDuration]);

  return queryInfo;
};

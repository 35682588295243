import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@mui/material';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

import { useLanguageContext } from '~providers';

const LANGUAGE_OPTIONS = [
  { value: 'en-US', flag: 'us', labelKey: 'language.english' },
  { value: 'es-DO', flag: 'do', labelKey: 'language.dominicanSpanish' },
  { value: 'es-MX', flag: 'mx', labelKey: 'language.mexicanSpanish' },
  { value: 'de', flag: 'de', labelKey: 'language.german' },
  { value: 'fr-FR', flag: 'fr', labelKey: 'language.french' },
  { value: 'sv-SE', flag: 'se', labelKey: 'language.swedish' },
];

/**
 * @deprecated Use `LanguageSelectV1` - with FF `CONFIGURABLE_WEB_APP_BANNER` instead.
 * @deprecationReason To release web app banner feature
 */
export const LanguageSelect: React.FC = () => {
  const { locale, selectLanguage } = useLanguageContext();
  const { t } = useTranslation();

  return (
    <Select
      value={locale}
      onChange={(e) => selectLanguage(e.target.value)}
      size="small"
      sx={{ backgroundColor: 'white' }}
    >
      {LANGUAGE_OPTIONS.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {`${getUnicodeFlagIcon(option.flag)} ${t(option.labelKey)}`}
        </MenuItem>
      ))}
    </Select>
  );
};

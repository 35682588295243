/* eslint-disable max-len */

export const StationOffline = () => {
  return (
    <svg
      width="121"
      height="120"
      viewBox="0 0 121 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2130_16692"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="121"
        height="120"
      >
        <rect x="0.5" width="120" height="120" fill="white" />
      </mask>
      <g mask="url(#mask0_2130_16692)">
        <rect x="6.5" y="22" width="108" height="92" rx="12" fill="#009EFA" />
        <mask
          id="mask1_2130_16692"
          maskUnits="userSpaceOnUse"
          x="14"
          y="43"
          width="93"
          height="63"
        >
          <rect x="14.5" y="43" width="92" height="63" rx="6" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask1_2130_16692)">
          <rect
            width="100"
            height="66"
            transform="translate(10.5 39)"
            fill="#ECF8FF"
          />
          <path
            d="M10.0005 95.5C36.2025 91.1666 89.5006 67.1666 100.001 38.6666H111L110 110H13.0005C7.40235 106.1 10.0005 99.7 10.0005 95.5Z"
            fill="#D3F0FF"
          />
          <path
            d="M46.7012 86H75.5012"
            stroke="#0067B1"
            strokeWidth="3.6"
            strokeLinecap="round"
          />
          <path
            d="M57.5008 62C57.5008 67.3019 53.2027 71.6 47.9008 71.6C42.5988 71.6 38.3008 67.3019 38.3008 62"
            stroke="#0067B1"
            strokeWidth="3.6"
            strokeLinecap="round"
          />
          <path
            d="M83.9012 62C83.9012 67.3019 79.6031 71.6 74.3012 71.6C68.9992 71.6 64.7012 67.3019 64.7012 62"
            stroke="#0067B1"
            strokeWidth="3.6"
            strokeLinecap="round"
          />
        </g>
        <rect x="14.5" y="30" width="15" height="9" rx="4" fill="#ECF8FF" />
        <path
          d="M93.0996 35.3672H107.1L93.0996 49.3672H107.1"
          stroke="#0067B1"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M103.5 17.7666H113.5L103.5 27.7666H113.5"
          stroke="#0067B1"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M109.1 5.9668H115.1L109.1 11.9668H115.1"
          stroke="#0067B1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

import React from 'react';
import { Box, Typography } from '@mui/material';

import { LoadingButtonNavigation } from '~components/atoms/custom';

export const ErrorPage: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: 'white', // Set background color to white
        width: '100%',
        p: 2,
      }}
    >
      <Typography
        variant="h1"
        sx={{
          color: (theme) => theme.palette.neutral[900],
          marginBottom: 2,
        }}
      >
        404
      </Typography>
      <Typography
        variant="h6"
        textAlign="center"
        sx={{
          color: (theme) => theme.palette.neutral[600],
          marginBottom: 3,
        }}
      >
        The page you're looking for doesn't exist.
      </Typography>
      <LoadingButtonNavigation fullWidth={false} path={'/'}>
        <Typography variant="body2" fontWeight={500}>
          Return to homepage
        </Typography>
      </LoadingButtonNavigation>
    </Box>
  );
};

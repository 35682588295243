import { useMount } from 'react-use';
import { CircularProgress, Stack } from '@mui/material';

import { STEP } from '~common';
import { StepOne, StepThree, StepTwo } from '~components/organisms';
import { useMainContext } from '~providers';

export const RentalPage = () => {
  const { step, initialComplete } = useMainContext();

  useMount(() => {
    window?.scrollTo({
      top: 0,
      behavior: 'smooth', // Scroll smoothly
    });
  });

  if (!initialComplete) {
    return (
      <Stack
        flex={1}
        justifyContent={'center'}
        alignItems="center"
        height={'100vh'}
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack p={3} display="grid" sx={{ gridRowGap: 40 }}>
      {step === STEP.INPUT_PHONE_NUMBER && <StepOne />}
      {step === STEP.INPUT_OTP && <StepTwo />}
      {step === STEP.INPUT_PAYMENT_INFO && <StepThree />}
    </Stack>
  );
};

export enum QUERY_KEY {
  GET_RENTAL_INFO = 'GET_RENTAL_INFO',
  GET_PAYMENT_INFO = 'GET_PAYMENT_INFO',
  GET_RENTAL_STATE = 'GET_RENTAL_STATE',
  GET_STATION_PRE_AUTH = 'GET_STATION_PRE_AUTH',
  GET_LOGGED_IN_USER_INFO = 'GET_LOGGED_IN_USER_INFO',
  GET_APP_BANNER_SETTING_BY_IMEI_OR_SERIAL = 'GET_APP_BANNER_SETTING_BY_IMEI_OR_SERIAL',
  GET_STATION_STATUS_BY_IMEI_OR_SERIAL = 'GET_STATION_STATUS_BY_IMEI_OR_SERIAL',
}

export enum STEP {
  INPUT_PHONE_NUMBER = 'INPUT_PHONE_NUMBER',
  INPUT_OTP = 'INPUT_OTP',
  INPUT_PAYMENT_INFO = 'INPUT_PAYMENT_INFO',
}

export enum PAYMENT_METHOD {
  CREDIT_CARD = 'CREDIT_CARD',
  APPLE_PAY = 'APPLE_PAY',
}

export enum RENTAL_STATE {
  WAITING = 'WAITING',
  ACTIVE = 'ACTIVE',
  ENDED = 'ENDED',
  OVERDUE = 'OVERDUE',
  FAILED = 'FAILED',
}

export enum API_ENDPOINT_KEYS {
  SEND_CODE = 'SEND_CODE',
  VERIFY_CODE = 'VERIFY_CODE',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  GET_PROFILE = 'GET_PROFILE',
}

export const SERVICE_RENTAL_ENDPOINTS: { [key in API_ENDPOINT_KEYS]: string } =
  {
    [API_ENDPOINT_KEYS.SEND_CODE]: 'api/oauth/send-code',
    [API_ENDPOINT_KEYS.VERIFY_CODE]: 'api/oauth/verify-code',
    [API_ENDPOINT_KEYS.REFRESH_TOKEN]: 'api/oauth/access-token',
    [API_ENDPOINT_KEYS.GET_PROFILE]: 'api/user/me',
  };

export const SERVICE_AUTH_ENDPOINTS: { [key in API_ENDPOINT_KEYS]: string } = {
  [API_ENDPOINT_KEYS.SEND_CODE]: 'api/v1/auth/send-code',
  [API_ENDPOINT_KEYS.VERIFY_CODE]: 'api/v1/auth/verify-code',
  [API_ENDPOINT_KEYS.REFRESH_TOKEN]: 'api/oauth2/token',
  [API_ENDPOINT_KEYS.GET_PROFILE]: 'api/v1/user/me',
};

export const RENTAL_FAILED_REASON: Record<string, string> = {
  NO_RENTABLE_BATTERY:
    'All batteries are currently recharging. Please try again later.',
  FAILED_TO_INVOKE_IOT: 'Error when communicating with station',
  PRE_AUTH_PAYMENT_FAILED: 'Can not process pre-auth payment for this rental',
  TIMED_OUT: 'Request timed out',
};

export enum FEATURE_FLAGS {
  'CONFIGURABLE_WEB_APP_BANNER' = 'CONFIGURABLE_WEB_APP_BANNER',
  'WEB_APP_STATION_BAD_STATUS_FLOW' = 'WEB_APP_STATION_BAD_STATUS_FLOW',
}

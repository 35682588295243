// Banner Call-to-action Types
export enum BannerCtaType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  NONE = 'NONE',
}

export interface AppBannerSetting {
  id: string;
  name: string;
  content: string;
  ctaText?: string;
  ctaLink?: string;
  ctaType?: BannerCtaType;
}

import { Outlet, useLocation } from 'react-router-dom';
import { Box, Stack } from '@mui/material';

import { Captcha } from '~components/atoms';
import { LanguageSelectV1 } from '~components/molecules';
import { NavbarWrapper } from '~components/molecules/navbar/NavbarWrapper';
import { LocalizationTypography } from '~components/organisms';
import { BAD_STATION_STATUS_PAGE_PATH, SUCCESS_PAGE_PATH } from '~configs';
import {
  EventTrackingProvider,
  MainProvider,
  useAppBannerSettingContext,
  useStationStatusContext,
} from '~providers';

export const MainLayout: React.FC = () => {
  const location = useLocation();
  const disableHeader = location.pathname === SUCCESS_PAGE_PATH;
  const enableTextCustomerSupport = !location.pathname.includes(
    BAD_STATION_STATUS_PAGE_PATH,
  );

  const { configurableWebAppBannerFeature } = useAppBannerSettingContext();
  const { badStatusStationFlowEnabled } = useStationStatusContext();

  return (
    <EventTrackingProvider>
      <MainProvider>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            height: 'fit-content',
            maxWidth: '1024px',
            margin: 'auto',
          }}
        >
          {/* Navbar */}
          {!disableHeader && <NavbarWrapper />}

          {/* Captcha */}
          <Stack alignItems={'center'}>
            <Captcha />
          </Stack>

          {/* Outlet display */}
          <Stack
            sx={{
              justifyContent: 'space-between',
              flex: 1,
              overflow: 'auto',
              position: 'relative',
            }}
          >
            <Stack
              sx={{
                flex: 1,
              }}
            >
              <Outlet />
            </Stack>

            <Stack
              sx={{
                gap: 2,
                alignItems: 'center',
                padding: 3.5,
              }}
            >
              {/* DISABLE TEXT SUPPORT ON BAD STATUS STATION PAGE */}
              {(!badStatusStationFlowEnabled || enableTextCustomerSupport) && (
                <Stack
                  sx={{
                    bgcolor: 'white',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <img
                    src="/images/chat-bubble.svg"
                    alt="chat-icon"
                    width={'24'}
                  />
                  <Box
                    component={'a'}
                    href="sms:+1 (213) 451-6667"
                    sx={{ textDecoration: 'unset' }}
                  >
                    <LocalizationTypography
                      variant="body2"
                      fontWeight={500}
                      color={'primary'}
                      marginLeft={1}
                      textId="app.mainLayout.customerSupportText"
                      defaultText="Questions? Text customer support"
                    />
                  </Box>
                </Stack>
              )}

              {configurableWebAppBannerFeature && <LanguageSelectV1 />}
            </Stack>
          </Stack>
        </Box>
      </MainProvider>
    </EventTrackingProvider>
  );
};

import { useTranslation } from 'react-i18next';
import { Language } from '@mui/icons-material';
import { InputAdornment, MenuItem, Select } from '@mui/material';

import { useLanguageContext } from '~providers';

const LANGUAGE_OPTIONS = [
  { value: 'en-US', labelKey: 'language.english' },
  { value: 'es-DO', labelKey: 'language.dominicanSpanish' },
  { value: 'es-MX', labelKey: 'language.mexicanSpanish' },
  { value: 'de', labelKey: 'language.german' },
  { value: 'fr-FR', labelKey: 'language.french' },
  { value: 'sv-SE', labelKey: 'language.swedish' },
];

export const LanguageSelectV1: React.FC = () => {
  const { locale, selectLanguage } = useLanguageContext();
  const { t } = useTranslation();

  return (
    <Select
      startAdornment={
        <InputAdornment position="start">
          <Language />
        </InputAdornment>
      }
      value={locale}
      onChange={(e) => selectLanguage(e.target.value)}
      size="small"
      sx={{
        width: 'fit-content',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      }}
      inputProps={{
        IconComponent: () => null,
        sx: { paddingRight: '14px !important' },
      }} // remove dropdown icon
    >
      {LANGUAGE_OPTIONS.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {t(option.labelKey)}
        </MenuItem>
      ))}
    </Select>
  );
};

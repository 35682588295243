import { useMemo } from 'react';

import { CustomComponent } from '../custom';

import { NavbarViewProps } from './type';

import { LocalizationTypography } from '~components/organisms';
import { handleOpenApp } from '~utils';

export const NavbarViewV1: React.FC<NavbarViewProps> = ({
  leftComponent,
  rightComponent,
}) => {
  const defaultLeftComponent = useMemo(
    () => ({
      onlyChildren: true,
      children: <img src="/images/logo-black.svg" alt="logo" width={'130'} />,
    }),
    [],
  );

  const defaultRightComponent = useMemo(
    () => ({
      onClick: handleOpenApp,
      children: (
        <LocalizationTypography
          textId="app.mainLayout.buttonUseApp"
          defaultText="Use App"
          sx={{
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
          }}
        />
      ),
    }),
    [],
  );

  return (
    <>
      {/* DEFAULT IS CF LOGO */}
      {leftComponent !== 'empty' && (
        <CustomComponent {...(leftComponent ?? defaultLeftComponent)} />
      )}

      {/* DEFAULT IS USE APP BUTTON */}
      {rightComponent !== 'empty' && (
        <CustomComponent {...(rightComponent ?? defaultRightComponent)} />
      )}
    </>
  );
};

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { QUERY_KEY } from '~common';
import { getRequest } from '~services';
import { AppBannerSetting } from '~types/app-banner.type';

type ReqParams = {
  box: string;
};

export const useQueryGetAppBannerSettingByImeiOrSerial = (
  reqParams: ReqParams,
  options?: Omit<
    UseQueryOptions<
      AppBannerSetting,
      AxiosError<Record<string, string>>,
      AppBannerSetting,
      (QUERY_KEY | string)[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const getSetting = (): Promise<AppBannerSetting> =>
    getRequest({
      path: 'api/admin/app-banner-setting/by-imei-or-serial',
      params: reqParams,
    });

  return useQuery(
    [QUERY_KEY.GET_APP_BANNER_SETTING_BY_IMEI_OR_SERIAL, reqParams.box],
    () => getSetting(),
    {
      ...options,
    },
  );
};

import { forwardRef, useMemo } from 'react';
import { Box, Stack, SxProps } from '@mui/material';

import { NavbarView } from './NavbarView';
import { NavbarViewV1 } from './NavbarViewV1';
import { NavbarViewProps } from './type';

import { FEATURE_FLAGS, STEP } from '~common';
import { RENTAL_PAGE_PATH } from '~configs';
import { useMainContext } from '~providers';
import { checkEnabledFeatureFlag } from '~utils';

type NavbarProps = NavbarViewProps & {
  sxProps?: SxProps;
};

export const Navbar = forwardRef<HTMLDivElement, NavbarProps>(
  ({ sxProps = {}, ...navBarViewProps }, ref) => {
    const configurableWebAppBannerFeature = checkEnabledFeatureFlag(
      FEATURE_FLAGS.CONFIGURABLE_WEB_APP_BANNER,
    );
    const { step } = useMainContext();

    const progress = useMemo(() => {
      const complete = 1; // 100%
      switch (step) {
        case STEP.INPUT_PHONE_NUMBER:
          return complete / 3;
        case STEP.INPUT_OTP:
          return (complete * 2) / 3;
        case STEP.INPUT_PAYMENT_INFO:
          return complete;
        default:
          return complete;
      }
    }, [step]);

    const shouldEnableProgressBar =
      location.pathname.includes(RENTAL_PAGE_PATH); // rental page

    return (
      <Box
        ref={ref}
        sx={{
          top: 0,
          width: '100%',
          height: 'auto',
          position: 'sticky',

          display: 'flex',
          flexDirection: 'column',

          zIndex: 100,
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',

            py: 3,
            px: 2,

            bgcolor: '#ECF8FF',
            borderBottom: '1px solid #CCEDFF',

            ...sxProps,
          }}
        >
          {configurableWebAppBannerFeature ? (
            <NavbarViewV1 {...navBarViewProps} />
          ) : (
            <NavbarView />
          )}
        </Stack>

        {/* PROGRESS BAR */}
        {shouldEnableProgressBar && (
          <Box width="100%" height={'4px'} bgcolor={'#EBFBFF'}>
            <Box
              width={progress}
              height={'100%'}
              bgcolor={'#47D6FA'}
              borderRadius={2}
            />
          </Box>
        )}
      </Box>
    );
  },
);

// Set display name for better debugging
Navbar.displayName = 'Navbar';

import { useState } from 'react';
import { NavigateOptions } from 'react-router-dom';

import { useNavigateInApp } from '.';

const DEFAULT_DELAY = 1000;

export const useNavigateInAppWithDelay = (delay = DEFAULT_DELAY) => {
  const [isNavigating, setNavigating] = useState(false);
  const navigate = useNavigateInApp();

  const delayedNavigate = (
    path: string,
    options?: NavigateOptions,
    onNavigate?: () => void,
  ) => {
    setNavigating(true);

    setTimeout(() => {
      onNavigate && onNavigate();
      navigate(path, options);
    }, delay);
  };

  return { navigate: delayedNavigate, isNavigating };
};

export enum TRACKING_EVENTS {
  BUTTON_CLICKED = 'BUTTON_CLICKED',
}

export enum TRACKING_BUTTON {
  SEND_VERIFICATION_CODE = 'SEND_VERIFICATION_CODE',
  CONFIRM_VERIFICATION_CODE = 'CONFIRM_VERIFICATION_CODE',
  RESEND_VERIFICATION_CODE = 'RESEND_VERIFICATION_CODE',
  EJECT_BATTERY = 'EJECT_BATTERY',
  BANNER_CTA = 'BANNER_CTA',
}

export enum TRACKING_PAGES {
  HOME = 'HOME',
  ENTER_PHONE_NUMBER = 'ENTER_PHONE_NUMBER',
  ENTER_VERIFICATION_CODE = 'ENTER_VERIFICATION_CODE',
  SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD',
  WELCOME_BACK = 'WELCOME_BACK',
  SUCCESS = 'SUCCESS',
  OFFLINE_STATION = 'OFFLINE_STATION',
  WEB_BANNER_DISPLAYED = 'WEB_BANNER_DISPLAYED',
}

import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMount } from 'react-use';
import { Button, Skeleton, Stack } from '@mui/material';
import { snakeCase } from 'lodash';

import { stationStatusMessages } from './constants';

import { LocalizationTypography } from '~components/organisms';
import { HOMEPAGE_PATH } from '~configs';
import { useNavigateInApp } from '~hooks';
import { useStationStatusContext } from '~providers';
import { StationStatus } from '~types/station.type';
import { handleOpenApp, handleOpenLinkInNewTab } from '~utils';

export const BadStatusStationPage: FC = () => {
  const { status } = useParams();
  const {
    badStatusStationFlowEnabled,
    stationOnlineStatus,
    isFetchingStationOnlineStatus,
  } = useStationStatusContext();
  const navigate = useNavigateInApp();

  const stationStatus = snakeCase(status).toUpperCase() as StationStatus;
  // Convert `status` to a valid `StationStatus`
  const validStationStatus = Object.values(StationStatus).includes(
    stationStatus,
  )
    ? stationStatus
    : null;

  // Redirect on mount if the status is invalid or the FFg is off
  useMount(() => {
    if (!validStationStatus || !badStatusStationFlowEnabled) {
      navigate('/error');
    }
  });

  // Redirect to homepage if station is online
  useEffect(() => {
    if (stationOnlineStatus === true) {
      navigate(HOMEPAGE_PATH);
    }
  }, [navigate, stationOnlineStatus]);

  if (
    !validStationStatus ||
    !badStatusStationFlowEnabled ||
    isFetchingStationOnlineStatus
  ) {
    return <Skeleton height={200} />;
  }

  const stationStatusMessage = stationStatusMessages[validStationStatus];

  return (
    <Stack
      sx={{
        gap: 4,
        backgroundColor: 'white', // Set background color to white
        width: '100%',
        p: 2.5,
      }}
    >
      <Stack
        spacing={2.5}
        sx={{
          alignItems: 'center',
        }}
      >
        {stationStatusMessage.img && stationStatusMessage.img()}

        <LocalizationTypography
          textId={stationStatusMessage.localeTextId}
          defaultText={stationStatusMessage.title}
          sx={{
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '32px',
          }}
        />

        <LocalizationTypography
          textId="app.badStatusStationPage.text.guiding"
          defaultText="Please check back later or find nearby stations in our app. Need more
          help? Text support for assistance."
          sx={{
            fontWeight: 400,
            color: 'text.secondary',
          }}
        />
      </Stack>

      {/* BUTTONS */}
      <Stack sx={{ gap: 2 }}>
        {/* BUTTON USE APP */}
        <Button
          variant="contained"
          color="primary"
          sx={{ borderRadius: 5, fontFamily: 'Poppins' }}
          onClick={handleOpenApp}
        >
          <LocalizationTypography
            variant="body2"
            sx={{
              fontWeight: 500,
            }}
            textId="app.badStatusStationPage.button.findStationsInApp"
            defaultText="Find Stations in App"
          />
        </Button>

        {/* BUTTON TEXT USER SUPPORT */}
        <Button
          variant="outlined"
          color="primary"
          sx={{ borderRadius: 5, fontFamily: 'Poppins' }}
          onClick={handleOpenLinkInNewTab('sms:+1 (213) 451-6667')}
        >
          <LocalizationTypography
            variant="body2"
            sx={{
              fontWeight: 500,
            }}
            textId="app.badStatusStationPage.button.textUserSupport"
            defaultText="Text User Support"
          />
        </Button>
      </Stack>
    </Stack>
  );
};

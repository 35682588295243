/* eslint-disable max-len */

export const NoChargersAvailable = () => {
  return (
    <svg
      width="121"
      height="120"
      viewBox="0 0 121 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2130_16750"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="121"
        height="120"
      >
        <rect x="0.5" width="120" height="120" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2130_16750)">
        <ellipse cx="60.5" cy="97" rx="56" ry="19" fill="#ECF8FF" />
        <path d="M29.5 48H91.5L91.6741 104H29.5V48Z" fill="#0067B1" />
        <path d="M29.5 48V67H23.5V64L29.5 48Z" fill="#009EFA" />
        <path d="M91.5 48V67H97.5V64L91.5 48Z" fill="#009EFA" />
        <path
          d="M23.5 64H97.5V101.464C97.5 105.074 94.5737 108 90.9639 108H30.0361C26.4263 108 23.5 105.074 23.5 101.464V64Z"
          fill="#74E0FB"
        />
        <mask
          id="mask1_2130_16750"
          maskUnits="userSpaceOnUse"
          x="4"
          y="23"
          width="33"
          height="32"
        >
          <rect x="4.5" y="23" width="32" height="32" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask1_2130_16750)">
          <path
            d="M12.5 32L25 43"
            stroke="#009EFA"
            strokeWidth="3.26807"
            strokeLinecap="round"
          />
          <path
            d="M6.5 50.0446L21.5 51.5"
            stroke="#009EFA"
            strokeWidth="3.26807"
            strokeLinecap="round"
          />
          <path
            d="M30.5 25.1842L33.5 39.5"
            stroke="#009EFA"
            strokeWidth="3.26807"
            strokeLinecap="round"
          />
        </g>
        <mask
          id="mask2_2130_16750"
          maskUnits="userSpaceOnUse"
          x="76"
          y="4"
          width="41"
          height="42"
        >
          <rect x="76.5" y="4" width="40" height="42" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask2_2130_16750)">
          <path d="M78.5 44L89.5 42L80.5 33L78.5 44Z" fill="#DA1E28" />
          <path
            d="M96.5 26.1L102.95 32.55C103.25 32.85 103.6 33 104 33C104.4 33 104.75 32.85 105.05 32.55C105.35 32.25 105.5 31.9 105.5 31.5C105.5 31.1 105.35 30.75 105.05 30.45L98.6 24L105.05 17.55C105.35 17.25 105.5 16.9 105.5 16.5C105.5 16.1 105.35 15.75 105.05 15.45C104.75 15.15 104.4 15 104 15C103.6 15 103.25 15.15 102.95 15.45L96.5 21.9L90.05 15.45C89.75 15.15 89.4 15 89 15C88.6 15 88.25 15.15 87.95 15.45C87.65 15.75 87.5 16.1 87.5 16.5C87.5 16.9 87.65 17.25 87.95 17.55L94.4 24L87.95 30.45C87.65 30.75 87.5 31.1 87.5 31.5C87.5 31.9 87.65 32.25 87.95 32.55C88.25 32.85 88.6 33 89 33C89.4 33 89.75 32.85 90.05 32.55L96.5 26.1ZM96.5 44C93.7667 44 91.1833 43.475 88.75 42.425C86.3167 41.375 84.1917 39.9417 82.375 38.125C80.5583 36.3083 79.125 34.1833 78.075 31.75C77.025 29.3167 76.5 26.7333 76.5 24C76.5 21.2333 77.025 18.6333 78.075 16.2C79.125 13.7667 80.5583 11.65 82.375 9.85C84.1917 8.05 86.3167 6.625 88.75 5.575C91.1833 4.525 93.7667 4 96.5 4C99.2667 4 101.867 4.525 104.3 5.575C106.733 6.625 108.85 8.05 110.65 9.85C112.45 11.65 113.875 13.7667 114.925 16.2C115.975 18.6333 116.5 21.2333 116.5 24C116.5 26.7333 115.975 29.3167 114.925 31.75C113.875 34.1833 112.45 36.3083 110.65 38.125C108.85 39.9417 106.733 41.375 104.3 42.425C101.867 43.475 99.2667 44 96.5 44Z"
            fill="#DA1E28"
          />
          <path
            d="M96.4163 26.01L102.84 32.4336C103.139 32.7324 103.487 32.8818 103.886 32.8818C104.284 32.8818 104.633 32.7324 104.931 32.4336C105.23 32.1349 105.379 31.7863 105.379 31.3879C105.379 30.9896 105.23 30.641 104.931 30.3422L98.5077 23.9186L104.931 17.495C105.23 17.1962 105.379 16.8477 105.379 16.4493C105.379 16.0509 105.23 15.7024 104.931 15.4036C104.633 15.1048 104.284 14.9554 103.886 14.9554C103.487 14.9554 103.139 15.1048 102.84 15.4036L96.4163 21.8272L89.9927 15.4036C89.6939 15.1048 89.3453 14.9554 88.947 14.9554C88.5486 14.9554 88.2001 15.1048 87.9013 15.4036C87.6025 15.7024 87.4531 16.0509 87.4531 16.4493C87.4531 16.8477 87.6025 17.1962 87.9013 17.495L94.3249 23.9186L87.9013 30.3422C87.6025 30.641 87.4531 30.9896 87.4531 31.3879C87.4531 31.7863 87.6025 32.1349 87.9013 32.4336C88.2001 32.7324 88.5486 32.8818 88.947 32.8818C89.3453 32.8818 89.6939 32.7324 89.9927 32.4336L96.4163 26.01Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};

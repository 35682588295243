import { Button, ButtonProps, CircularProgress } from '@mui/material';

import { useNavigateInAppWithDelay } from '~hooks/useNavigateInAppWithDelay';

type Props = ButtonProps & {
  isLoading?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
};

export const LoadingButton: React.FC<Props> = ({
  isLoading = false,
  children,
  disabled,
  ...props
}) => (
  <Button
    fullWidth={props.fullWidth ?? true}
    size="large"
    variant="contained"
    color="primary"
    sx={{
      borderRadius: '100px',
    }}
    disabled={disabled || isLoading}
    {...props}
    startIcon={isLoading ? undefined : props.startIcon}
  >
    {isLoading ? <CircularProgress size={24} color="inherit" /> : children}
  </Button>
);

type NavigateProps = Props & {
  path: string;
};

export const LoadingButtonNavigation: React.FC<NavigateProps> = ({
  path,
  children,
  ...props
}) => {
  const { navigate, isNavigating } = useNavigateInAppWithDelay();

  return (
    <LoadingButton
      onClick={() => navigate(path)}
      isLoading={isNavigating}
      {...props}
    >
      {children}
    </LoadingButton>
  );
};

import { useEffect } from 'react';
import { Skeleton } from '@mui/material';

import { TRACKING_PAGES } from '~common';
import { WelcomeBack } from '~components/organisms';
import { BAD_STATION_STATUS_PAGE_PATH } from '~configs';
import { useNavigateInApp } from '~hooks/index';
import { useEventTrackingContext, useStationStatusContext } from '~providers';

export const WelcomeBackPage = () => {
  const {
    recordPageVisited,
    isStationOfflineRecorded,
    setIsStationOfflineRecorded,
  } = useEventTrackingContext();
  const {
    stationOnlineStatus,
    badStatusStationFlowEnabled,
    isFetchingStationOnlineStatus,
  } = useStationStatusContext();
  const navigate = useNavigateInApp();

  useEffect(() => {
    recordPageVisited(TRACKING_PAGES.WELCOME_BACK);
    if (!isStationOfflineRecorded && stationOnlineStatus === false) {
      recordPageVisited(TRACKING_PAGES.OFFLINE_STATION);
      setIsStationOfflineRecorded(true);
    }
  }, [
    badStatusStationFlowEnabled,
    isStationOfflineRecorded,
    recordPageVisited,
    setIsStationOfflineRecorded,
    stationOnlineStatus,
  ]);

  // Make sure station offline is recorded before navigation
  useEffect(() => {
    if (isStationOfflineRecorded && stationOnlineStatus === false) {
      navigate(`${BAD_STATION_STATUS_PAGE_PATH}/offline`);
    }
  }, [isStationOfflineRecorded, navigate, stationOnlineStatus]);

  // Station online status is fetching
  if (badStatusStationFlowEnabled && isFetchingStationOnlineStatus) {
    return <Skeleton height={200} />;
  }

  return <WelcomeBack />;
};

import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { FEATURE_FLAGS } from '~common';
import { BAD_STATION_STATUS_PAGE_PATH, HOMEPAGE_PATH } from '~configs';
import { useQueryGetAppBannerSettingByImeiOrSerial } from '~hooks/app-banner/useQueryGetAppBannerSettingByImeiOrSerial';
import { AppBannerSetting } from '~types/app-banner.type';
import { checkEnabledFeatureFlag } from '~utils';

type AppBannerSettingContextType = {
  appBannerSetting: AppBannerSetting | null;
  isAppBannerSettingReady: boolean;
  configurableWebAppBannerFeature: boolean;
};

const AppBannerSettingContext = createContext<AppBannerSettingContextType>({
  appBannerSetting: null,
  isAppBannerSettingReady: false,
  configurableWebAppBannerFeature: false,
});

export const AppBannerSettingProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const shouldNotShowOnSomePages = !(
    location.pathname === HOMEPAGE_PATH ||
    location.pathname.includes(BAD_STATION_STATUS_PAGE_PATH)
  );

  const stationImeiOrSerialNumber = searchParams.get('box');

  const configurableWebAppBannerFeature = checkEnabledFeatureFlag(
    FEATURE_FLAGS.CONFIGURABLE_WEB_APP_BANNER,
  );

  const queryGetAppBannerSetting = useQueryGetAppBannerSettingByImeiOrSerial(
    {
      box: stationImeiOrSerialNumber || '',
    },
    {
      enabled: !!stationImeiOrSerialNumber && configurableWebAppBannerFeature,
    },
  );

  const providerValues = useMemo(
    () => ({
      appBannerSetting: queryGetAppBannerSetting.data || null,
      isAppBannerSettingReady:
        !!queryGetAppBannerSetting.data &&
        !queryGetAppBannerSetting.isFetching &&
        shouldNotShowOnSomePages,
      configurableWebAppBannerFeature,
    }),
    [
      configurableWebAppBannerFeature,
      queryGetAppBannerSetting.data,
      queryGetAppBannerSetting.isFetching,
      shouldNotShowOnSomePages,
    ],
  );

  return (
    <AppBannerSettingContext.Provider value={providerValues}>
      {children}
    </AppBannerSettingContext.Provider>
  );
};

export const useAppBannerSettingContext = () =>
  useContext(AppBannerSettingContext);

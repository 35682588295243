import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { QUERY_KEY } from '~common';
import { getRequest } from '~services';

type ReqParams = {
  box: string;
};

type Response = {
  online: boolean;
};

export const useQueryGetStationStatusByImeiOrSerial = (
  reqParams: ReqParams,
  options?: Omit<
    UseQueryOptions<
      Response,
      AxiosError<Record<string, string>>,
      Response,
      (QUERY_KEY | string)[]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const getSetting = (): Promise<Response> =>
    getRequest({
      path: 'api/station/online-status',
      params: reqParams,
    });

  return useQuery(
    [QUERY_KEY.GET_STATION_STATUS_BY_IMEI_OR_SERIAL, reqParams.box],
    () => getSetting(),
    {
      ...options,
    },
  );
};

import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FEATURE_FLAGS } from '~common';
import { useQueryGetStationStatusByImeiOrSerial } from '~hooks/station/useQueryGetStationStatusByImeiOrSerial';
import { checkEnabledFeatureFlag } from '~utils';

type StationStatusContextType = {
  stationOnlineStatus?: boolean;
  badStatusStationFlowEnabled: boolean;
  isFetchingStationOnlineStatus: boolean;
};

const StationStatusContext = createContext<StationStatusContextType>({
  stationOnlineStatus: false,
  badStatusStationFlowEnabled: false,
  isFetchingStationOnlineStatus: false,
});

export const StationStatusProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const badStatusStationFlowEnabled = checkEnabledFeatureFlag(
    FEATURE_FLAGS.WEB_APP_STATION_BAD_STATUS_FLOW,
  );

  const [searchParams] = useSearchParams();

  const stationImeiOrSerialNumber = searchParams.get('box');

  const queryGetStationStatus = useQueryGetStationStatusByImeiOrSerial(
    {
      box: stationImeiOrSerialNumber || '',
    },
    {
      enabled: !!stationImeiOrSerialNumber && !!badStatusStationFlowEnabled,
    },
  );

  const providerValues = useMemo(
    () => ({
      stationOnlineStatus: queryGetStationStatus.data?.online,
      isFetchingStationOnlineStatus: queryGetStationStatus.isFetching,
      badStatusStationFlowEnabled,
    }),
    [
      badStatusStationFlowEnabled,
      queryGetStationStatus.data?.online,
      queryGetStationStatus.isFetching,
    ],
  );

  return (
    <StationStatusContext.Provider value={providerValues}>
      {children}
    </StationStatusContext.Provider>
  );
};

export const useStationStatusContext = () => useContext(StationStatusContext);
